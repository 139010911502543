<template>
  <v-card-text>
    <surveys-table
      :isLoading="isLoading"
      @updateSurveys="handleSurveysUpdate($event)"
    >
    </surveys-table>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SurveysTable from "@admin/components/surveys/SurveysTable.vue";

export default {
  name: "SurveysNewIndex",
  components: {
    SurveysTable,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["acceptRoles"]),
    showSurveyAddButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("surveysNew", ["getSurveys", "clearSurveys"]),
    async handleSurveysUpdate(options) {
      this.isLoading = true;
      await this.getSurveys(options);
      this.isLoading = false;
    },
  },
  async mounted() {
    if (!this.surveys || this.surveys.length === 0) {
      this.isLoading = true;
      await this.getSurveys();
      this.isLoading = false;
    }
    this.setBreadcrumbs();
    this.setTitleObject("Surveys");
    this.setActionButtons([
      {
        attributes: {
          to: "/surveys-new/add",
        },
        component: "AddButton",
        index: 0,
      },
    ]);
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearSurveys();
  },
};
</script>

<style scoped></style>
